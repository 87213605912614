<script setup lang="ts">
import {
  Ibar,
  Footer,
  IJournal,
  ILinksBlock,
  Newsletter,
  IIBar,
  IFooter
} from "@frontiers-vue3/public-pages";
import { prependBaseUrlIfRelativeUrl } from "~/helpers";
import { ICategory, ICategoryGroup } from "~/types/page-service";
import { mapCategoryGroupToLinkBlock } from "~/mappers/models";
import { useContentfulQuery } from "~/services";
import type { ISeo } from "~/types/contentful/models";
import { useIbarFooterService } from "~/services/useIbarFooterService";
import { getCategoriesByGroupLevel1 } from "~/helpers/getCategories";
import { ISEOResponse } from "~/types/contentful/responses/ILandingPageResponse";

const scienceNews: IJournal = {
  id: 901191,
  name: "Science news",
  slug: "science-news",
  url: prependBaseUrlIfRelativeUrl("/news")
};

const searchLink = {
  text: "Search news",
  url: "/news/all"
};

const mainLinks = [
  {
    text: "Research news",
    url: prependBaseUrlIfRelativeUrl("/news/category/research-news")
  },
  {
    text: "Frontiers updates",
    url: prependBaseUrlIfRelativeUrl("/news/category/frontiers-updates")
  },
  {
    text: "Open science",
    url: prependBaseUrlIfRelativeUrl("/news/category/open-science")
  },
  {
    text: "Press assets",
    url: "https://pressoffice.frontiersin.org/download-assets",
    target: "_blank"
  }
];

const ibarFooterService = useIbarFooterService();

let ibar: IIBar;
let footer: IFooter;

const loadIbar = async (): Promise<void> => {
  ibar = await ibarFooterService.getIbarComponent();
};

const loadFooter = async (): Promise<void> => {
  footer = await ibarFooterService.getFooterComponent();
};

await Promise.all([loadIbar(), loadFooter()]);

const contentfulService = useContentfulQuery();

const seo = ref<ISeo>(await contentfulService.getSEO());
const categories = await contentfulService.getCategoryList();

const postListPageSize = ref(15);
const postListPageTotal = ref(0);

useState<Array<ICategory>>("categories", () => categories);
provide("seo", seo);
provide("postListPageSize", postListPageSize);
provide("postListPageTotal", postListPageTotal);

const categoryGroups = computed((): Array<ILinksBlock> => {
  const categoriesLevel1 = getCategoriesByGroupLevel1(categories);
  return categoriesLevel1.map((group: ICategoryGroup) =>
    mapCategoryGroupToLinkBlock(group)
  );
});
</script>

<template>
  <h1 class="acc-hidden">Frontiers | Science News</h1>
  <Ibar
    v-bind="ibar"
    about-journal-text="All categories"
    :journal="scienceNews"
    :journal-links="mainLinks"
    :about-journal="categoryGroups"
    :search-link="searchLink" />
  <NuxtPage />

  <Newsletter
    title="Get the latest research updates, subscribe to our newsletter"
    subscription-type="sf_newsletter"
    button-text="Subscribe" />
  <Footer v-bind="footer" />
</template>
